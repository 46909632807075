import { VetsuiteOverview } from '@setvi/shared/services';

// remove when backend returns correct program details
export const getVetsuiteProgramName = (overview: VetsuiteOverview): string => {
  if (overview?.enrolmentSuffix)
    return `VetSuite ${overview?.enrolmentSuffix} (${overview?.enrolmentType}  ${overview?.enrolmentLevel})`;
  if (overview?.enrolmentType !== 'Independent')
    return `VetSuite (${overview?.enrolmentType}  ${overview?.enrolmentLevel})`;

  return `VetSuite ${overview?.enrolmentLevel}`;
};
