import { useSubscribedMutation } from '@setvi/shared/hooks';
import {
  GetCovetrusUser,
  GetVetsuiteToken,
  VetsuiteOverview,
  getCovetrusContact,
  getVetsuiteTokenWithOTP,
  startPasswordlessLoginVetsuite
} from '@setvi/shared/services';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ROUTES } from 'router/routes';
import { useDigitalShowroomContext } from '../../providers/app-provider/context';

export const useVetsuiteLogin = () => {
  const [email, setEmail] = useState<string>('');
  const [otpCode, setOtpCode] = useState<string>('');
  const [showOTPCodeField, setShowOTPCodeField] = useState<boolean>(false);
  const {
    setLoggedInUserEmail,
    covetrusUser,
    setCovetrusUser,
    token: dsToken
  } = useDigitalShowroomContext();
  const [token, setToken] = useState<string>(dsToken || '');
  const [showLocationSelection, setShowLocationSelection] = useState<boolean>(
    covetrusUser?.vetsuite?.length > 1
  );
  const navigate = useNavigate();
  const { mutateAsync: checkCovetrusContact, isLoading: emailCheckLoading } =
    useSubscribedMutation(getCovetrusContact());
  const {
    mutateAsync: startPasswordlessLogin,
    isLoading: passwordlessLoginLoading
  } = useSubscribedMutation(startPasswordlessLoginVetsuite());
  const { mutateAsync: getTokenByOTP, isLoading: getTokenLoading } =
    useSubscribedMutation(getVetsuiteTokenWithOTP());

  const handlePassowrdlessLogin = async (userEmail: string) => {
    try {
      await startPasswordlessLogin({ email: userEmail });
    } catch (error) {
      setShowOTPCodeField(false);
      enqueueSnackbar(
        'Failed to send send verification code, please try again later.',
        { variant: 'error' }
      );
    }
  };

  const handleEmailCheck = async (userEmail: string) => {
    try {
      const data: GetCovetrusUser = await checkCovetrusContact({
        contactEmail: userEmail,
        endpoint: process.env.CVET_ACCOUNT_CHECK_API
      });

      if (data?.contact.email) {
        setCovetrusUser(data);
        setEmail(userEmail);
        handlePassowrdlessLogin(userEmail);
        setShowOTPCodeField(true);
      }
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.message ||
          'The email was not recognized. Please get in touch with your VRM for assistance.',
        {
          variant: 'error'
        }
      );
    }
  };

  const handleOTPCode = async (OtpCode: string) => {
    setOtpCode(OtpCode);

    const data: GetVetsuiteToken = await getTokenByOTP({
      otpCode: OtpCode,
      email
    });

    if (data) {
      setLoggedInUserEmail(email);
      setToken(data.id_token);

      if (covetrusUser?.vetsuite?.length === 1) {
        return navigate(
          {
            pathname: ROUTES.HOME,
            search: createSearchParams({
              // eslint-disable-next-line no-underscore-dangle
              customerId: covetrusUser.vetsuite[0]._id,
              token: data.id_token
            }).toString()
          },
          { replace: true }
        );
      }

      setShowLocationSelection(covetrusUser?.vetsuite?.length > 1);
    }
  };

  const handleLocationSelection = (vetsuiteOverview: VetsuiteOverview) => {
    navigate(
      {
        pathname: ROUTES.HOME,
        search: createSearchParams({
          // eslint-disable-next-line no-underscore-dangle
          customerId: vetsuiteOverview._id,
          token
        }).toString()
      },
      { replace: true }
    );
  };

  return {
    email,
    showOTPCodeField,
    emailCheckLoading: emailCheckLoading || passwordlessLoginLoading,
    covetrusUser,
    showLocationSelection,
    getTokenLoading,
    otpCode,
    setOtpCode,
    handleEmailCheck,
    handleOTPCode,
    handleLocationSelection
  };
};
