import moment from 'moment';
import { useEffect } from 'react';
import { Box, Divider, Tooltip, styled } from '@mui/material';
import { SText } from '@setvi/shared/components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { VetsuiteOverview } from '@setvi/shared/services';
import { getPercentage } from '@setvi/shared/utils/common';
import { useVetSuiteDigitalShowroomAnalytics } from '../../../../hooks';
import { useDigitalShowroomContext } from '../../../../../../providers/app-provider/context';
import { ProgramIcons } from '../../../../providers/requirements';
import { ListOfBenefits } from '../../../../providers/benefits';
import { useVetsuiteDigitalShowroomContext } from '../../../../providers/context';
import { RadialChart, RadialChartTypeColor } from './radial-chart';
import { Milestone, Section } from '../../../../../../components';
import { getVetsuiteProgramName } from '../../../../utils';
import { EnrollmentLevel } from '../../../../providers/types';
import {
  getSoftwareUtilization,
  isRebateAvailable
} from '../utilities/covetrus-helpers';

export const IpadLink = styled(Box)(() => ({
  display: 'inline',
  cursor: 'pointer',
  textDecoration: 'underline',
  color: '#1E96FC',
  '&:hover': {
    color: '#004299'
  }
}));

const getQuartertoDateFromToday = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const quarter = Math.floor((month + 3) / 3);
  const quarterStart = new Date(year, 3 * quarter - 3, 1);
  const quarterEnd = new Date(year, 3 * quarter, 0);
  const daysLeft = Math.floor(
    (quarterEnd.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
  );
  return `(${quarterStart.toLocaleDateString()} - ${quarterEnd.toLocaleDateString()}), ${daysLeft} days left`;
};

// const getYearFromToday = () => {
//   const today = new Date();
//   const year = today.getFullYear();
//   const yearStart = new Date(year, 0, 1);
//   const yearEnd = new Date(year, 11, 31);
//   const daysLeft = Math.floor(
//     (yearEnd.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
//   );
//   return `(${yearStart.toLocaleDateString()} - ${yearEnd.toLocaleDateString()}), ${daysLeft} days left`;
// };

interface OverviewProps {
  overview: VetsuiteOverview;
  onProgramDetailsClick: () => void;
  onContactUsClick: () => void;
}

export const Overview = ({
  overview,
  onProgramDetailsClick,
  onContactUsClick
}: OverviewProps) => {
  // const matches = useMediaQuery('(max-width:1440px)');
  const { allAccounts, customerId } = useVetsuiteDigitalShowroomContext();
  const { captureAnalytics } = useVetSuiteDigitalShowroomAnalytics();
  const { loggedInUserEmail } = useDigitalShowroomContext();
  const targetAccountBenefits = allAccounts.find(
    acc =>
      acc.TypeOfCustomer === overview?.enrolmentType &&
      acc.EnrollmentLevel === overview?.enrolmentLevel
  );
  const qtdCompounding = getPercentage(
    overview?.compoundingQtd,
    overview?.compoundingCommitmentQtd
  );
  const qtdCompoundingNormalized = qtdCompounding > 100 ? 100 : qtdCompounding;
  const qtdCompoundingLabel = qtdCompounding === 0 ? null : qtdCompounding;
  const qtdDistribution = getPercentage(
    overview?.distributionQtd,
    overview?.distributionCommitmentQtd
  );
  const qtdDistributionNormalized =
    qtdDistribution > 100 ? 100 : qtdDistribution;
  const qtdDistributionLabel = qtdDistribution === 0 ? null : qtdDistribution;
  // const ytdCompounding = Math.round(
  //   (overview?.compoundingYtd / overview?.compoundingCommitmentYtd) * 100
  // );
  // const ytdCompoundingNormalized =
  //   ytdCompounding > overview?.commitmentLevel
  //     ? overview?.commitmentLevel
  //     : ytdCompounding;
  // const ytdCompoundingLabel = ytdCompounding === 0 ? null : ytdCompounding;
  // const ytdDistribution = Math.round(
  //   (overview?.distributionYtd / overview?.distributionCommitmentYtd) * 100
  // );
  // const ytdDistributionNormalized =
  //   ytdDistribution > overview?.commitmentLevel
  //     ? overview?.commitmentLevel
  //     : ytdDistribution;
  // const ytdDistributionLabel = ytdDistribution === 0 ? null : ytdDistribution;

  const softwareUtilization = getSoftwareUtilization(
    overview?.enrolmentLevel,
    overview?.pims
  );

  useEffect(() => {
    if (loggedInUserEmail)
      captureAnalytics({
        actionType: 'OverviewTab',
        userEmail: loggedInUserEmail,
        timestamp: new Date(),
        covetrusKey: customerId
      });
  }, [captureAnalytics, customerId, loggedInUserEmail]);

  return (
    <>
      <Section>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={theme => ({
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              alignItems: 'start'
            }
          })}>
          <h4 style={{ color: '#021660' }}>
            Your{' '}
            <span style={{ color: '#ED3030' }}>
              {getVetsuiteProgramName(overview)}
            </span>{' '}
            Performance
          </h4>
          <SText
            style={{ color: '#44444F' }}
            title={`Updated as of ${moment(overview?.updatedAt).format('l')}`}
            size="sm"
          />
        </Box>
        <Box
          mt={3}
          display="flex"
          justifyContent="center"
          gap={4}
          // sx={{
          //   flexDirection: matches && 'column'
          // }}
        >
          <Box
            flex={1}
            sx={theme => ({
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '8px',
              maxWidth: '600px'
            })}>
            <Box p={3} display="flex" alignItems="center">
              <SText title="Quarter to date" weight="semibold" />
              <Box
                ml={2}
                display="flex"
                alignItems="center"
                sx={theme => ({
                  [theme.breakpoints.down('sm')]: {
                    display: 'none'
                  }
                })}>
                <Tooltip
                  arrow
                  title="Quarterly rebate progress, inclusive of the current quarter">
                  <InfoOutlinedIcon htmlColor="#696974" />
                </Tooltip>
              </Box>
              <Box
                ml="auto"
                sx={theme => ({
                  color: theme.palette.grey[600],
                  [theme.breakpoints.down('sm')]: { marginLeft: '0' }
                })}>
                <SText title={getQuartertoDateFromToday()} size="xs" />
              </Box>
            </Box>
            <Divider />
            <Box mt={3}>
              <RadialChart
                data={[
                  {
                    progress: qtdCompoundingNormalized,
                    fill: RadialChartTypeColor.CompoundPerformance,
                    label:
                      qtdCompoundingLabel >= 100
                        ? '100'
                        : qtdCompoundingLabel?.toString()
                  },
                  {
                    progress: qtdDistributionNormalized,
                    fill: RadialChartTypeColor.DistributionPerformance,
                    label:
                      qtdDistributionLabel >= 100
                        ? '100'
                        : qtdDistributionLabel?.toString()
                  }
                ]}
                goal={overview?.commitmentLevel}
                endGoal={100}
              />
            </Box>
          </Box>
          {/* <Box
            flex={1}
            sx={theme => ({
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '8px'
            })}>
            <Box
              p={3}
              display="flex"
              alignItems="center"
              sx={theme => ({
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  alignItems: 'start'
                }
              })}>
              <SText title="Year to date" weight="semibold" />
              <Box
                ml={2}
                display="flex"
                alignItems="center"
                sx={theme => ({
                  [theme.breakpoints.down('sm')]: {
                    display: 'none'
                  }
                })}>
                <Tooltip
                  arrow
                  title="Annual rebate progress, inclusive of the current quarter">
                  <InfoOutlinedIcon htmlColor="#696974" />
                </Tooltip>
              </Box>
              <Box
                ml="auto"
                sx={theme => ({
                  color: theme.palette.grey[600],
                  [theme.breakpoints.down('sm')]: { marginLeft: '0' }
                })}>
                <SText title={getYearFromToday()} size="xs" />
              </Box>
            </Box>
            <Divider />
            <Box mt={3}>
              <RadialChart
                data={[
                  {
                    progress: ytdCompoundingNormalized,
                    fill: RadialChartTypeColor.CompoundPerformance,
                    label:
                      ytdCompoundingLabel >= overview?.commitmentLevel
                        ? ''
                        : ytdCompoundingLabel?.toString()
                  },
                  {
                    progress: ytdDistributionNormalized,
                    fill: RadialChartTypeColor.DistributionPerformance,
                    label:
                      ytdDistributionLabel >= overview?.commitmentLevel
                        ? ''
                        : ytdDistributionLabel?.toString()
                  }
                ]}
                goal={overview?.commitmentLevel}
              />
            </Box>
          </Box> */}
        </Box>
        <Box mt={4} display="flex" alignItems="center" justifyContent="center">
          <Box display="flex" alignItems="center">
            <Box
              mr={2}
              sx={{
                backgroundColor: '#021660',
                width: 10,
                height: 10,
                borderRadius: '50%'
              }}
            />
            <Box>Distribution Performance</Box>
          </Box>
          <Box ml={4} display="flex" alignItems="center">
            <Box
              mr={2}
              sx={{
                backgroundColor: '#27BDBE',
                width: 10,
                height: 10,
                borderRadius: '50%'
              }}
            />
            <Box>Compounding Performance</Box>
          </Box>
        </Box>
      </Section>
      <Box
        my={3}
        display="flex"
        justifyContent="center"
        zIndex={99}
        position="relative">
        {!overview?.enrolmentSuffix && (
          <Box
            fontSize={16}
            px={3}
            py={2}
            display="inline"
            textAlign="center"
            bgcolor="#fff"
            sx={theme => ({
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '20px'
            })}>
            <p>
              For more information regarding additional VetSuite Levels such as{' '}
              <IpadLink
                onClick={onProgramDetailsClick}
                style={{
                  fontWeight: 'bold',
                  color: '#021660',
                  cursor: 'pointer'
                }}>
                VetSuite (
                {overview?.enrolmentType !== 'Independent'
                  ? overview?.enrolmentType
                  : ''}
                {overview?.enrolmentType !== 'Independent' && ' '}
                Select) & VetSuite (
                {overview?.enrolmentType !== 'Independent'
                  ? overview?.enrolmentType
                  : ''}
                {overview?.enrolmentType !== 'Independent' && ' '}
                Premier)
              </IpadLink>
              , please check the{' '}
              <IpadLink
                style={{ fontWeight: 'bold', color: '#021660' }}
                onClick={onProgramDetailsClick}>
                Program Details
              </IpadLink>
              .
            </p>
          </Box>
        )}
      </Box>
      <Section>
        <Box
          mt={3}
          display="flex"
          gap={4}
          sx={theme => ({
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column'
            }
          })}>
          <Box flex={1}>
            <Box>
              <h4 style={{ color: '#021660' }}>
                {getVetsuiteProgramName(overview)} Requirements
              </h4>
              <Box mt={3}>
                <Milestone
                  icon={ProgramIcons.Truck}
                  title="Distribution Performance"
                  steps={[
                    {
                      completed:
                        getPercentage(
                          overview?.distributionQtd,
                          overview?.distributionCommitmentQtd
                        ) >= overview?.commitmentLevel,
                      description:
                        getPercentage(
                          overview?.distributionQtd,
                          overview?.distributionCommitmentQtd
                        ) >= overview?.commitmentLevel
                          ? `Commitment Met`
                          : `Currently at ${getPercentage(
                              overview?.distributionQtd,
                              overview?.distributionCommitmentQtd
                            )}%, an additional ${
                              overview?.commitmentLevel -
                              getPercentage(
                                overview?.distributionQtd,
                                overview?.distributionCommitmentQtd
                              )
                            }% is required to complete`
                    }
                  ]}
                />
              </Box>
              <Box mt={3}>
                <Milestone
                  icon={ProgramIcons.Bowl}
                  title="Compounding Performance"
                  steps={[
                    {
                      completed:
                        getPercentage(
                          overview?.compoundingQtd,
                          overview?.compoundingCommitmentQtd
                        ) >= overview?.commitmentLevel,
                      description:
                        getPercentage(
                          overview?.compoundingQtd,
                          overview?.compoundingCommitmentQtd
                        ) >= overview?.commitmentLevel
                          ? `Commitment Met`
                          : `Currently at ${getPercentage(
                              overview?.compoundingQtd,
                              overview?.compoundingCommitmentQtd
                            )}%, an additional ${
                              overview?.commitmentLevel -
                              getPercentage(
                                overview?.compoundingQtd,
                                overview?.compoundingCommitmentQtd
                              )
                            }% is required to complete`
                    }
                  ]}
                />
              </Box>
              <Box mt={3}>
                <Milestone
                  icon={ProgramIcons.VrxPro}
                  title="Fully Configured vRxPro"
                  steps={[
                    {
                      completed: overview?.fullyConfigured,
                      description: overview?.fullyConfigured
                        ? 'Configured'
                        : 'Not Configured'
                    }
                  ]}
                />
              </Box>
              <Box mt={3}>
                {overview?.enrolmentLevel !== EnrollmentLevel.Core && (
                  <Milestone
                    title="Covetrus Software Utilization"
                    icon={ProgramIcons.Cloud}
                    steps={[
                      ...(overview?.pims
                        ? [
                            {
                              completed: softwareUtilization.isCovetrusPIMS,
                              description: softwareUtilization.name
                            }
                          ]
                        : []),
                      {
                        completed: overview?.communicationsSystem,
                        description: 'Communications'
                      },
                      {
                        completed: overview?.paymentsSystem,
                        description: 'Payments'
                      }
                    ]}
                  />
                )}
              </Box>
              {/* {account.Requirements.DistributionPreferredPartner && (
                <Box mt={3}>
                  <SText
                    title={account.Requirements.DistributionPreferredPartner}
                    weight="bold"
                    size="xl"
                  />
                  <Box mt={3}>
                    <Milestone
                      steps={[{ completed: true, description: 'Selected' }]}
                    />
                  </Box>
                </Box>
              )} */}
            </Box>
          </Box>
          <Box flex={1}>
            <Box>
              <h4 style={{ color: '#021660' }}>
                {overview?.enrolmentType !== 'Independent'
                  ? overview?.enrolmentType
                  : ''}{' '}
                Exclusive Benefits -{' '}
                {overview?.rebateAmmount?.toLocaleString('en-US', {
                  currency: 'USD',
                  style: 'currency',
                  maximumFractionDigits: 0
                })}{' '}
                (est. value)
              </h4>
              <Box mt={3}>
                <Milestone
                  icon={ProgramIcons.Dollar}
                  title={`Rebate is ${overview?.fixedRebateDVM?.toLocaleString(
                    'en-US',
                    {
                      currency: 'USD',
                      style: 'currency',
                      maximumFractionDigits: 0
                    }
                  )} per DVM`}
                  steps={[
                    {
                      completed: isRebateAvailable(
                        overview,
                        Math.round(
                          (overview?.distributionYtd /
                            overview?.distributionCommitmentYtd) *
                            100
                        ),
                        Math.round(
                          (overview?.compoundingYtd /
                            overview?.compoundingCommitmentYtd) *
                            100
                        )
                      ),
                      description: `Total Rebate: ${(
                        overview?.fixedRebateDVM * overview?.fte
                      ).toLocaleString('en-US', {
                        currency: 'USD',
                        style: 'currency',
                        maximumFractionDigits: 0
                      })}`
                    }
                  ]}
                />
              </Box>
              {ListOfBenefits.filter(
                benefit => targetAccountBenefits?.Benefits[benefit.key]
              ).map(bf => (
                <Box key={bf.title} mt={3}>
                  <Milestone
                    steps={[{ completed: true, description: bf.title }]}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          mt={7}
          mb={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between">
          <p>
            For more information regarding program details, please{' '}
            <IpadLink onClick={onContactUsClick}>click here</IpadLink> or
            contact your Veterinary Relationship Manager.
          </p>
        </Box>
      </Section>
    </>
  );
};
