import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Divider } from '@mui/material';
import { SText } from '@setvi/shared/components';
import { ProgramProps } from '../program';
import { CurrentLevelPill } from '../current-level-pill';
import { PerformanceRequirement } from '../performance-requirement';
import { ProgramValue } from '../program-value';
import { Milestone } from '../../../../../../../components';
import { IpadLink } from '../../overview';

export const ProgramAccordion = ({
  programName,
  isProgramSelected,
  benefits,
  benefitsTitle,
  estimatedTotalValue,
  onContactAccountManager,
  performanceRequirements,
  techRequirements,
  prefferedPartner,
  prefferedPartnerTitle,
  useBulletPointsForBenefits,
  zoetisDiagnosticCommitment
}: ProgramProps) => (
  <Accordion sx={{ padding: '30px 0px' }}>
    <AccordionSummary sx={{ width: '100%' }} expandIcon={<ExpandMoreIcon />}>
      <Box width="100%" px={3}>
        <Box mb={2}>
          <SText size="xl" weight="bold" style={{ color: '#021660' }}>
            {programName}
          </SText>
        </Box>
        <Typography>
          <b>For information only</b>,{' '}
          {onContactAccountManager ? (
            <IpadLink
              style={{ cursor: 'pointer' }}
              onClick={onContactAccountManager}>
              Please contact your Veterinary Relationship Manager
            </IpadLink>
          ) : (
            'Please contact your Veterinary Relationship Manager'
          )}{' '}
          for Enrollment Details
        </Typography>
      </Box>
    </AccordionSummary>
    <AccordionDetails sx={{ padding: '0px 16px 16px 16px' }}>
      {isProgramSelected && <CurrentLevelPill />}
      <Box my={4}>
        <SText title="Performance Requirements" size="lg" weight="bold" />
      </Box>
      <Box>
        {performanceRequirements.map((requirement, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box mb={3} key={index}>
            <PerformanceRequirement
              icon={requirement.icon}
              title={requirement.title}
              description={requirement.description}
              currentProgress={requirement.currentProgress}
              sliderProgressTitle={requirement.sliderProgressTitle}
              sliderProgress={requirement.sliderProgress}
            />
          </Box>
        ))}
      </Box>
      <Box my="20px">
        <Divider />
      </Box>
      <Box mb={2}>
        <SText title="Tech Requirements" size="lg" weight="bold" />
      </Box>
      <Box>
        {techRequirements.map((requirement, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box mb={3} key={index}>
            <Milestone
              icon={requirement.icon}
              title={requirement.title}
              steps={requirement.steps}
            />
          </Box>
        ))}
      </Box>
      <Box my="20px">
        <Divider />
      </Box>
      {prefferedPartner && prefferedPartnerTitle && (
        <>
          {prefferedPartnerTitle && (
            <Box mb={2}>
              <SText title={prefferedPartnerTitle} size="lg" weight="bold" />
            </Box>
          )}
          <Box>
            {prefferedPartner.map((requirement, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box mb={3} key={index}>
                <Milestone
                  icon={requirement.icon}
                  title={requirement.title}
                  steps={requirement.steps}
                />
              </Box>
            ))}
          </Box>
          <Box my="20px">
            <Divider />
          </Box>
        </>
      )}
      {zoetisDiagnosticCommitment && (
        <>
          <Box my={2}>
            <SText
              title="Zoetis Diagnostic Commitment"
              size="lg"
              weight="bold"
            />
          </Box>
          <Box>
            {zoetisDiagnosticCommitment.map((requirement, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box mb={3} key={index}>
                <Milestone
                  icon={requirement.icon}
                  title={requirement.title}
                  steps={requirement.steps}
                />
              </Box>
            ))}
          </Box>
          <Box my={2}>
            <Divider />
          </Box>
        </>
      )}
      <Box my="20px">
        <SText title={benefitsTitle} size="lg" weight="bold" />
      </Box>
      <Box>
        <Box mb={3}>
          <ProgramValue
            estimatedTotalValue={estimatedTotalValue}
            valueHighlighted
          />
        </Box>
        {benefits.map((requirement, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={index} mb={3}>
            <Milestone
              icon={requirement.icon}
              title={requirement.title}
              steps={requirement.steps}
              useBulletPoints={useBulletPointsForBenefits && !requirement.icon}
            />
          </Box>
        ))}
      </Box>
    </AccordionDetails>
  </Accordion>
);
